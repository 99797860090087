import { CSRF_TOKEN } from "../constants";

export default (authenticated) => ({
  firstName: "",
  email: "",
  password: "",

  authenticated,
  errorMessage: null,
  showPasswordField: false,

  async attemptAuthentication() {
    try {
      const response = await fetch("/authentication", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": CSRF_TOKEN,
        },
        body: JSON.stringify({
          first_name: this.firstName,
          email: this.email,
          password: this.password,
        }),
      });

      let json;
      try {
        json = await response.json();
      } catch (error) {
        this.errorMessage = "An unexpected error occurred.";
        return;
      }

      if (!response.ok && json.errors) {
        // Get the first error message for the first field.
        const key = Object.keys(json.errors)[0];
        this.errorMessage = json.errors[key][0];

        return;
      } else {
        this.errorMessage = json.message || "An unexpected error occurred.";
      }

      if (json.two_factor) {
        window.location.pathname = "/two-factor-challenge";

        return;
      }

      Array.from(document.querySelectorAll("input[name=_token]")).map(
        (input) => (input.value = json.token),
      );

      this.authenticated = true;

      this.$refs.submit.click();
    } catch (e) {
      console.error("Authentication failed", e);
    }
  },

  submit(event) {
    if (this.authenticated) {
      return;
    }

    const form = event.target.closest("form");
    if (!form.reportValidity()) {
      return;
    }

    event.preventDefault();
    this.attemptAuthentication();
  },
});
