export default (userId, unreadMessages) => ({
  unreadMessages,

  init() {
    Echo.private(`App.Models.User.${userId}`).notification((notification) => {
      if (notification.unread_messages !== undefined) {
        this.unreadMessages = notification.unread_messages;
      }
    });
  },
});
