const colors = {
  moderations: {
    bg: "rgba(54, 162, 235, 0.5)",
    border: "rgba(54, 162, 235, 1)",
  },
  watchlists: {
    bg: "rgba(75, 192, 192, 0.5)",
    border: "rgba(75, 192, 192, 1)",
  },
  bans: {
    bg: "rgba(255, 99, 132, 0.5)",
    border: "rgba(255, 99, 132, 1)",
  },
};

export default ({ datasets, title }) => ({
  init() {
    const now = new Date();
    const tzOffset = now.getTimezoneOffset() * 60000;

    const hourLabels = Array.from({ length: 72 }, (_, i) => {
      const d = new Date(now - (71 - i) * 3600000);
      return `${d.getHours().toString().padStart(2, "0")}:00`;
    });

    const chartDatasets = Object.entries(datasets).map(([key, data]) => {
      const counts = Array(72).fill(0);
      data.forEach((item) => {
        const itemDate = new Date(item.date_hour);
        const localDate = new Date(itemDate.getTime() - tzOffset);
        const hoursSince = Math.floor((now - localDate) / 3600000);
        if (hoursSince < 72) {
          counts[71 - hoursSince] = parseInt(item.count);
        }
      });

      return {
        label: key.charAt(0).toUpperCase() + key.slice(1),
        data: counts,
        backgroundColor: colors[key].bg,
        borderColor: colors[key].border,
        borderWidth: 1,
        tension: 0.1,
      };
    });

    const shiftMarkers = [];
    for (let i = 0; i < 72; i += 12) {
      shiftMarkers.push({
        type: "line",
        xMin: i,
        xMax: i,
        borderColor: "rgba(255, 99, 132, 0.25)",
        borderWidth: 2,
      });
    }

    new Chart(this.$refs.canvas, {
      type: "line",
      data: {
        labels: hourLabels,
        datasets: chartDatasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            reverse: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: true,
            position: "top",
          },
          annotation: {
            annotations: shiftMarkers,
          },
        },
      },
    });
  },
});
