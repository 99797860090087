import fetchRetry from "fetch-retry";
const fetchWithRetry = fetchRetry(fetch);

export default async function (query) {
  if (query.length < 3) {
    return [];
  }

  try {
    const response = await fetchWithRetry(`/api/locations?q=${query}`, {
      cache: "force-cache",
    });

    if (!response.ok) {
      return [];
    }

    const json = await response.json();

    return json.data;
  } catch (error) {
    console.error("Error parsing JSON or fetching locations", error);

    return [];
  }
}
