import { CSRF_TOKEN } from "../constants";
import { upload } from "../api/cloudinary";

export default ({ path, redirect }) => ({
  loading: false,

  get label() {
    return this.loading ? "Uploading..." : "Select photo";
  },

  onClick() {
    this.$refs.input.click();
  },

  onChange(event) {
    this.handleFiles(event.target.files);
  },

  async handleFiles(files) {
    this.loading = true;

    const uploads = Array.from(files).map((file) => this.uploadFile(file));

    try {
      await Promise.all(uploads);

      this.loading = false;

      this.onSuccess();
    } catch (error) {
      console.error(error);

      this.loading = false;

      alert(
        `Something has gone wrong with the upload, please try again later or contact support.`,
      );
    }
  },

  async uploadFile(file) {
    const response = await upload(file);

    if (response === null) {
      alert(
        "Something has gone wrong, please try again later or contact support.",
      );
      return;
    }

    const json = await response.json();

    return fetch(path, {
      headers: {
        "X-CSRF-TOKEN": CSRF_TOKEN,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        public_id: json.public_id,
        perception_hash: json.phash,
      }),
    }).catch((error) => {
      console.error(error);
      alert(
        "Something has gone wrong, please try again later or contact support.",
      );
    });
  },

  onSuccess() {
    window.location.href = redirect;
  },
});
