import { CLOUDINARY_CLOUD, CLOUDINARY_KEY, CSRF_TOKEN } from "../constants";

const authorize = async () => {
  try {
    const response = await fetch("/cloudinary", {
      method: "POST",
      headers: {
        "X-CSRF-TOKEN": CSRF_TOKEN,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      return null;
    }

    return response.json();
  } catch (error) {
    console.error("Error getting signature", error);

    return null;
  }
};

export const upload = async (file) => {
  const authorization = await authorize();

  if (!authorization) {
    return null;
  }

  const formData = new FormData();
  formData.set("file", file);
  formData.set("api_key", CLOUDINARY_KEY);
  formData.set("timestamp", authorization.timestamp);
  formData.set("signature", authorization.signature);
  formData.set("phash", true);

  try {
    return fetch(
      `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD}/image/upload`,
      {
        method: "POST",
        body: formData,
      },
    );
  } catch (error) {
    console.error("Error uploading image", error);

    return null;
  }
};
