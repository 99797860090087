import { GEO_REGION, MAPBOX_ACCESS_TOKEN } from "../constants";
import fetchRetry from "fetch-retry";
const fetchWithRetry = fetchRetry(fetch);

export default async function (
  query,
  { includeAddresses = false, includeStates = false },
) {
  const includeRegions = includeStates || GEO_REGION === "SG";

  const types = includeAddresses
    ? ["address"]
    : [
        includeRegions && "region",
        "postcode",
        "district",
        "place",
        "locality",
        "neighborhood",
      ];

  const params = new URLSearchParams({
    access_token: MAPBOX_ACCESS_TOKEN,
    country: GEO_REGION,
    proximity: "ip",
    types: types.filter((x) => x).join(","),
  });

  const response = await fetchWithRetry(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?${params}`,
    {
      cache: "force-cache",
    },
  ).catch((error) => {
    console.error(
      `Error fetching locations with query ${query} and params ${params}`,
      error,
    );

    return null;
  });

  if (!response) {
    return [];
  }

  try {
    const json = await response.json();

    if (!json.features) {
      return [];
    }

    return json.features
      .filter((feature) => feature.context)
      .map((feature) => normalize(feature));
  } catch (error) {
    console.error("Error parsing JSON", response, error);

    return [];
  }
}

const splitId = (id) => id.split(".");

const normalize = (result) => {
  let components = result.place_type.includes("address")
    ? addressComponents(result)
    : locationComponents(result);

  result.context.forEach((record) => {
    const [key, id] = splitId(record.id);
    components[key] = record.text;
  });

  return components;
};

const addressComponents = (result) => ({
  id: result.id,
  street_number: result.address,
  route: result.text,
  place_name: removeCountry(result.place_name),
  latitude: result.center[1],
  longitude: result.center[0],
});

const locationComponents = (result) => {
  const [key, id] = splitId(result.id);

  return {
    id: result.id,
    [key]: result.text,
    type: result.place_type[0],
    place_name: removeCountry(result.place_name),
    latitude: result.center[1],
    longitude: result.center[0],
  };
};

const removeCountry = (placeName) =>
  placeName.split(", ").slice(0, -1).join(", ");
