<template>
  <div>
    <MapboxAutocomplete
      placeholder="Search location..."
      :include-states="true"
      :select="onSelect"
      class="relative flex h-10 w-full items-center justify-center gap-4 rounded-full border-2 border-pink-light py-1 pl-10 transition-all hover:border-pink-lighter focus:border-pink-lighter focus:outline-none focus:ring-2 focus:ring-pink-light focus:ring-offset-2 focus:ring-offset-pink active:border-pink-lightest"
    >
    </MapboxAutocomplete>

    <LocationFields :location="state.location"> </LocationFields>
  </div>
</template>

<script setup>
import { reactive, nextTick } from "vue";

let state = reactive({
  location: {},
});

async function onSelect(newLocation) {
  state.location = { ...newLocation, id: null, district: null };

  await nextTick();

  this.$el.closest("form")?.submit();
}
</script>
