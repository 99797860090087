export default ({ data, title, yLabel }) => ({
  init() {
    const now = new Date();
    const tzOffset = now.getTimezoneOffset() * 60000;

    const hourLabels = Array.from({ length: 72 }, (_, i) => {
      const d = new Date(now - (71 - i) * 3600000);
      return `${d.getHours().toString().padStart(2, "0")}:00`;
    });

    const counts = Array(72).fill(0);
    data.forEach((item) => {
      const itemDate = new Date(item.date_hour);
      const localDate = new Date(itemDate.getTime() - tzOffset);
      const hoursSince = Math.floor((now - localDate) / 3600000);
      if (hoursSince < 72) {
        counts[71 - hoursSince] = parseFloat(item.count);
      }
    });

    const shiftMarkers = [];
    for (let i = 0; i < 72; i += 12) {
      shiftMarkers.push({
        type: "line",
        xMin: i,
        xMax: i,
        borderColor: "rgba(255, 99, 132, 0.25)",
        borderWidth: 2,
      });
    }

    new Chart(this.$refs.canvas, {
      type: "line",
      data: {
        labels: hourLabels,
        datasets: [
          {
            label: yLabel,
            data: counts,
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            reverse: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: true,
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.parsed.y} ${yLabel}`;
              },
            },
          },
          annotation: {
            annotations: shiftMarkers,
          },
        },
      },
    });
  },
});
