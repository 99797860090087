import "./bootstrap";

import { createApp, onMounted } from "vue";

const vueApp = () => {
  const app = createApp({
    setup() {
      onMounted(() => {
        document.querySelector("[autofocus]")?.focus();
      });
    },
  });

  const components = import.meta.glob("./components/**/*.vue", { eager: true });

  Object.entries(components).forEach(([path, definition]) => {
    app.component(path.split("/").pop().split(".")[0], definition.default);
  });

  return app;
};

document.querySelectorAll("[data-vue]").forEach((element) => {
  vueApp().mount(element);
});
