import * as Sentry from "@sentry/browser";

export const APPLICATION_KEY = "roomies-sentry-app-key";

export const initSentry = () => {
  const GEO_REGION = document.querySelector('meta[name="geo-region"]').content;

  if (GEO_REGION !== "NZ" && GEO_REGION !== "SG") {
    return;
  }

  const environment = import.meta.env.VITE_APP_ENV;

  if (environment === "production") {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
      environment,
      integrations: [
        Sentry.captureConsoleIntegration({
          levels: ["warn", "error"],
        }),
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: [APPLICATION_KEY],
          behaviour: "drop-error-if-exclusively-contains-third-party-frames",
        }),
      ],
      beforeSend(event) {
        const frames = event.exception?.values?.[0]?.stacktrace?.frames;

        const blockedSourcePrefixes = [
          "chrome-extension:",
          "webkit-masked-url:",
        ];
        const filename = frames?.[0]?.filename;
        if (
          filename &&
          blockedSourcePrefixes.some((term) => filename.startsWith(term))
        ) {
          return null;
        }

        const ignoredFiles = [
          "mapbox-gl.js",
          "maplibre-gl.js",
          "analytics.js",
          "<anonymous>",
        ];

        const lastFrame = frames?.[frames.length - 1];
        if (
          lastFrame?.filename &&
          ignoredFiles.some((file) => lastFrame.filename.endsWith(file))
        ) {
          return null;
        }

        return event;
      },
      attachStacktrace: true,
      ignoreErrors: [
        /Failed to initialize WebGL/,
        /Failed to fetch/,
        /Load failed/,
        /Invalid call to runtime\.sendMessage\(\)\. Tab not found\./,
      ],
    });
  }
};
