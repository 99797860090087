import {
  isSameDay,
  isToday,
  isYesterday,
  format,
  formatDistanceToNow,
} from "date-fns";

export default (singleMessage, previousMessage, userId) => ({
  message: singleMessage,
  previousMessage,
  isSender: userId === singleMessage.sender.id,
  sentToday: isToday(new Date(singleMessage.created_at)),
  sentYesterday: isYesterday(new Date(singleMessage.created_at)),
  isSameDayAsPrevious: previousMessage
    ? isSameDay(
        new Date(singleMessage.created_at),
        new Date(previousMessage.created_at),
      )
    : false,
  isSameSenderAsPrevious: previousMessage
    ? singleMessage.sender.id === previousMessage.sender.id
    : false,

  formatted: format(new Date(singleMessage.created_at), "d LLLL"),

  expiresAt:
    singleMessage.messageable &&
    singleMessage.messageable.expires_at &&
    formatDistanceToNow(singleMessage.messageable.expires_at, {
      addSuffix: true,
    }),

  aboutRoom: singleMessage.messageable?.type === "App\\Models\\Room",
  aboutProfile: singleMessage.messageable?.type === "App\\Models\\Profile",
  aboutBackgroundCheck:
    singleMessage.messageable?.type === "App\\Models\\BackgroundCheck",
  aboutPhoneNumberView:
    singleMessage.messageable?.type === "App\\Models\\PhoneNumberView",
  messageableUrl: singleMessage.messageable?.url,
  messageableExpiresAt: singleMessage.messageable?.expires_at,

  init() {
    this.$watch("singleMessage", (updatedMessage) => {
      this.message = updatedMessage;
    });
  },
});
