import Textoverlay from "textoverlay";

export default () => ({
  init() {
    new Textoverlay(this.$el, [
      {
        match: /\B@\w+/g, // social media handle
        css: { "background-color": "#FCDF9C" },
      },
      {
        match: /\S+@\S+\.\S+/g, // email address
        css: { "background-color": "#FCDF9C" },
      },
      {
        match: /\d{3}.?\d{3}.?\d{4}/g, // phone number
        css: { "background-color": "#FCDF9C" },
      },
      {
        match: /https?:\/\/\S+/g,
        css: { "background-color": "#FCDF9C" },
      },
    ]);
  },
});
