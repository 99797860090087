import { STRIPE_KEY } from "../constants";

export default ({ clientSecret }) => ({
  async startVerification() {
    const stripe = Stripe(STRIPE_KEY);

    try {
      const result = await stripe.verifyIdentity(clientSecret);

      this.$refs.form.submit();
    } catch (error) {
      alert("An error has occurred, please refresh and try again.");
    }
  },
});
