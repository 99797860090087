<template>
  <div class="relative">
    <Combobox as="div" v-model="selectedResult" nullable>
      <ComboboxInput
        :id="id"
        @change="onChange"
        :placeholder="placeholder"
        :display-value="(result) => result?.place_name || ''"
        :class="$attrs.class || 'form-control form-control-lg'"
        :disabled="disabled"
      />
      <ComboboxOptions
        class="absolute z-10 mt-2 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <ComboboxOption
          as="template"
          v-slot="{ active, selected }"
          v-for="result in results"
          :key="result.id"
          :value="result"
        >
          <MapboxAutocompleteResult
            :result="result"
            :active="active"
            :include-addresses="includeAddresses"
          />
        </ComboboxOption>

        <div
          v-if="results.length === 0 && !isLoading && query.length < 3"
          class="relative cursor-default select-none px-4 py-2 text-gray-700"
        >
          Please keep typing...
        </div>
        <div
          v-if="isLoading"
          class="relative cursor-default select-none px-4 py-2 text-gray-700"
        >
          Loading results...
        </div>
        <div
          v-if="results.length === 0 && !isLoading && query.length >= 3"
          class="relative cursor-default select-none px-4 py-2 text-gray-700"
        >
          Nothing found.
        </div>
      </ComboboxOptions>
    </Combobox>
  </div>
</template>

<script>
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { debounce } from "lodash-es";
import geocoder from "../../geocoder";

export default {
  inheritAttrs: false,

  props: {
    id: String,
    select: Function,
    initial: {
      default: "",
    },
    clearAfterSelection: {
      default: false,
    },
    disabled: {
      default: false,
    },
    includeAddresses: Boolean,
    includeStates: Boolean,
    placeholder: {
      type: String,
      default: "Type to search...",
    },
  },

  components: {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
  },

  data() {
    return {
      results: [],
      selectedResult: this.initial,
      isLoading: false,
      query: "",
    };
  },

  methods: {
    onChange(event) {
      this.query = event.target.value;

      this.debounceSearch(this.query);
    },

    debounceSearch: debounce(
      function (query) {
        this.search(query);
      },
      750,
      { trailing: true },
    ),

    async search(query) {
      if (query.length === 0) {
        return;
      }

      this.isLoading = true;

      const geocode = geocoder(this.includeAddresses);

      this.results = await geocode(query, {
        includeAddresses: this.includeAddresses,
        includeStates: this.includeStates,
      });

      this.isLoading = false;
    },
  },

  watch: {
    selectedResult(option) {
      if (!option) {
        return;
      }

      this.select?.(option);

      if (this.clearAfterSelection) {
        this.selectedResult = null;
      }
    },
  },
};
</script>
