import { initSentry } from "./sentry.js";

initSentry();

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import "./echo";

import {
  Livewire,
  Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";

// Alpine Plugins

import ui from "@alpinejs/ui";

Alpine.plugin(ui);

// Alpine data components

const components = import.meta.glob("./data/**/*.js", { eager: true });

Object.entries(components).forEach(([path, definition]) => {
  Alpine.data(path.split("/").pop().split(".")[0], definition.default);
});

// Alpine magic

import clipboard from "./magic/clipboard";

Alpine.magic("clipboard", clipboard);

import "livewire-sortable";

Livewire.start();
